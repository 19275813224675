








import { AmountDisplayTs } from './AmountDisplayTs';

export default class AmountDisplay extends AmountDisplayTs {}
